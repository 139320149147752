import React from "react";
import { useState } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import AnimationFade from "../animations/fade.component";
import { BgImage } from "../images/background-image.component";

export const DoubleImageBanner = ({ data }: any) => {
  const isMobile = useIsMobile();
  return (
    <AnimationFade>
      <div className="max-w-7xl mx-auto w-full">
        <div
          className={`md:flex ${
            data.direction ? "flex-row-reverse" : "flex-row"
          } md:h-[626px]`}
        >
          <div className="w-full md:w-4/6 px-4 md:px-0">
            <BgImage
              src={data.images[0]}
              style={{ height: isMobile ? "250px" : "100%" }}
            />
          </div>
          <div className="w-full md:w-2/6 px-4 md:px-0">
            <BgImage
              src={data.images[1]}
              style={{ height: isMobile ? "250px" : "100%" }}
            />
          </div>
        </div>
      </div>
    </AnimationFade>
  );
};
