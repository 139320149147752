import React from "react";

export const LineSeparator = ({ data }: any) => (
  <div className="max-w-8xl md:mx-auto w-full h-1 px-10 md:px-0">
    <div
      className="h-full"
      style={{ backgroundColor: data.color ? data.color : "#7E7E7E" }}
    />
  </div>
);
