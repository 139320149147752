import React, { MutableRefObject, ReactElement, useRef, useState } from "react";
import { useWindowScroll } from "../../hooks/useWIndowScroll";
import { MenuItems } from "../component-loop/loop.component";
import { animated, CSS, useSpring, useSprings } from "react-spring";
import { Logo } from "../logo/logo.component";
import { ActiveMenu } from "./active.component";
import { Link } from "gatsby";
import useIsMobile from "../../hooks/useIsMobile";
import { Menu, X } from "react-feather";
import useWindowHeight from "../../hooks/useWindowHeight";

type MenuProps = {
  data: MenuItems[];
  location: any;
};

const isActive = (pathname: string, slug: string): boolean => {
  if (pathname === "/" && slug === "/home") {
    return true;
  } else if (pathname === slug) {
    return true;
  } else {
    return false;
  }
};

const MenuItem = ({ item, location }) => {
  const link = item.slug === "home" ? "" : item.slug;
  const absolutePath = "/" + item.uri.split("/")[1];
  const active = isActive(location.pathname, absolutePath);
  console.log("ACTIVE", active, location);

  return (
    <Link to={"/" + link}>
      <li
        className={`font-body font-light ml-8 relative text-white uppercase tracking-[.25em] `}
      >
        <div
          className={`mx-2 pb-3 transition-colors duration-500 border-b  ${
            active
              ? "border-b border-white border-opacity-100"
              : "hover:border-b hover:border-white hover:border-opacity-100 border-white border-opacity-0"
          }`}
        >
          {item.name}
        </div>
      </li>
    </Link>
  );
};

const MobileMenuItem = ({ item, location, close }) => {
  const link = item.slug === "home" ? "" : item.slug;
  const absolutePath = "/" + item.uri.split("/")[1];
  const active = isActive(location.pathname, absolutePath);

  return (
    <>
      {active ? (
        <button onClick={close} className="uppercase">
          {item.name}
        </button>
      ) : (
        <Link to={"/" + link}>{item.name}</Link>
      )}
    </>
  );
};

export const Nav = ({ data, location }: MenuProps) => {
  const isMobile = useIsMobile();
  const { scrollY } = useWindowScroll();
  const windowHeight = useWindowHeight();
  const [open, setOpen] = useState(false);
  const sticky = scrollY > 50;

  const stickyMenu = useSpring<CSSAnimation>({
    marginTop: sticky
      ? isMobile
        ? "0px"
        : "24px"
      : isMobile
      ? "16px"
      : "32px",
    fontSize: sticky ? "12px" : "14px",
  });

  const stickyLogo = useSpring<CSSAnimation>({
    height: sticky ? "64px" : "94px",
  });

  const stickyContainter = useSpring<CSSAnimation>({
    paddingTop: sticky ? "16px" : "40px",
    paddingBottom: !sticky ? "0px" : "16px",
  });

  const stickyLogoMobile = useSpring<CSSAnimation>({
    height: sticky ? "54px" : "84px",
  });

  const stickyContainterMobile = useSpring<CSSAnimation>({
    paddingTop: sticky ? "24px" : "72px",
    paddingBottom: sticky ? "0px" : "32px",
  });

  const revealAnimation = useSpring<CSSAnimation>({
    height: open ? windowHeight + "px" : "0px",
  });

  const buttonAnimationMobile = useSpring<CSSAnimation>({
    transform: sticky ? "translate(8px,-4px)" : "translate(8px, 0px)",
  });

  const rotationAnimation = useSpring<CSSAnimation>({
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
  });

  const menuItems = data.slice(0, 3);

  const menuSprings = useSprings(
    menuItems.length,
    menuItems.map((item, index) => ({
      delay: 80 * index,
      transform: open ? "translateY(0px)" : "translateY(150px)",
      opacity: open ? 1 : 0,
    }))
  );

  return (
    <>
      {!isMobile && (
        <>
          <animated.div className="fixed w-full top-0 left-0 z-50 bg-black bg-opacity-80 backdrop-blur">
            <animated.div
              style={stickyContainter}
              className="max-w-7xl mx-auto flex flex-col-reverse md:flex-row justify-center px-8 pb-8"
            >
              <animated.ul style={stickyMenu} className="flex mr-10">
                {menuItems.slice(1, 2).map((item) => (
                  <MenuItem item={item} location={location} key={item.slug} />
                ))}
              </animated.ul>
              <animated.div style={stickyLogo} className="h-[94px] flex">
                <Logo />
              </animated.div>

              <animated.ul style={stickyMenu} className="flex ml-2">
                {menuItems
                  .slice(2, 3)
                  .map(
                    (item) =>
                      item.slug !== "home" && (
                        <MenuItem
                          item={item}
                          location={location}
                          key={item.slug}
                        />
                      )
                  )}
              </animated.ul>
            </animated.div>
          </animated.div>
          <div className="h-[154px]" />
        </>
      )}
      {isMobile && (
        <>
          <animated.div className="fixed w-full top-0 left-0 z-50 bg-black bg-opacity-90 backdrop-blur">
            <div className="w-full flex justify-end absolute">
              <animated.button
                style={buttonAnimationMobile}
                onClick={() => {
                  setOpen(true);
                }}
                className="p-2 mr-8 mt-8"
              >
                <svg
                  width="40"
                  height="20"
                  viewBox="0 0 40 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    y1="1.25"
                    x2="40"
                    y2="1.25"
                    stroke="#FAFAFA"
                    stroke-width="1.5"
                  />
                  <line
                    y1="10.25"
                    x2="40"
                    y2="10.25"
                    stroke="#FAFAFA"
                    stroke-width="1.5"
                  />
                  <line
                    y1="19.25"
                    x2="40"
                    y2="19.25"
                    stroke="#FAFAFA"
                    stroke-width="1.5"
                  />
                </svg>
              </animated.button>
            </div>
            <animated.div
              style={stickyContainterMobile}
              className="max-w-7xl mx-auto flex px-8"
            >
              <animated.div
                style={stickyLogoMobile}
                className="flex justify-around w-full"
              >
                <Logo />
              </animated.div>
            </animated.div>
            <div className="mt-4" />
            {/* <div
              className="h-px mx-4 md:w-full max-w-7xl md:mx-auto bg-[#7E7E7E] mt-4"
              style={{ transform: "translateY(2px)" }}
            /> */}
          </animated.div>
          <div className="md:h-[154px] h-[124px]" />
        </>
      )}
      <animated.div
        style={revealAnimation}
        className="fixed w-screen bg-black top-0 left-0 z-50 overflow-hidden"
      >
        <div className="absolute top-8 right-8">
          <animated.button
            style={rotationAnimation}
            onClick={() => setOpen(false)}
          >
            <X width={32} height={32} color="#fff" />
          </animated.button>
        </div>
        <div className="h-full flex flex-col justify-center px-8">
          <ul>
            {menuSprings.map((styles, index) => (
              <animated.li
                key={`menu_mobile_${index}`}
                style={styles}
                className="block text-white text-center uppercase text-2xl font-display border-b border-white py-8"
              >
                <MobileMenuItem
                  item={data[index]}
                  location={location}
                  close={() => {
                    setOpen(false);
                  }}
                />
              </animated.li>
            ))}
          </ul>
        </div>
      </animated.div>
    </>
  );
};
