import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import useIsMobile from "../../hooks/useIsMobile";
import AnimationFade from "../animations/fade.component";
import { BgImage } from "../images/background-image.component";

export const LeftRightCaptionComponent = ({ data }) => {
  const isMobile = useIsMobile();

  return (
    <div className="md:py-40 py-16">
      <AnimationFade>
        <div className="max-w-7xl mx-auto relative">
          <div
            className={`flex ${
              data.direction
                ? "flex-col md:flex-row"
                : "flex-col-reverse md:flex-row-reverse"
            }`}
          >
            <div className="md:w-2/5 md:pl-10 lg:pl-10 md:pr-10 pl-4 pr-4">
              <AnimationFade>
                <BgImage
                  src={data.images[0]}
                  style={{ minHeight: isMobile ? "300px" : "564px" }}
                />
                <div className="pl-10 pr-4 md:pr-0">
                  <div className="border-l-4 border-silver-gray pl-8">
                    <div className="pt-4">
                      <h4 className="text-xl font-black max-w-xs">
                        “We believe that charities are the experts in the work
                        they do. And that our role is to help maximise their
                        impact
                        <br />- by giving better.”
                      </h4>
                    </div>
                  </div>
                </div>
              </AnimationFade>
            </div>
            <div className="md:w-3/5 px-8 md:px-20 flex flex-col justify-center">
              <AnimationFade>
                <div
                  className="typography--container colored--typography--container max-w-lg"
                  dangerouslySetInnerHTML={{ __html: data.body }}
                />
              </AnimationFade>
            </div>
          </div>
        </div>
      </AnimationFade>
    </div>
  );
};
