import React, { useEffect, useRef } from "react";
import useStateIfMounted from "../../hooks/useStateIfMounted.hook";
import { animated, useSpring } from "react-spring";
import { useSwipeable } from "react-swipeable";
import AnimationFade from "../animations/fade.component";
import { LayoutWrapper, WideLayoutWrapper } from "../layout/wrapper.component";
import useIsMobile from "../../hooks/useIsMobile";
import { BgImage } from "../images/background-image.component";

export const GoalsSliderComponent = ({ data }) => {
  const isMobile = useIsMobile();
  const [slide, setSlide] = useStateIfMounted(0);
  const [slideWidth, setSlideWidth] = useStateIfMounted(0);
  const slideRef = useRef<HTMLDivElement>();
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });
  const [showName, setShowName] = useStateIfMounted(true);

  useEffect(() => {
    setSlideWidth(slideRef.current.clientWidth);
  }, [slideRef]);

  const transition = useSpring({
    from: {
      width: `${data.length * slideWidth}px`,
      transform: "translateX(0px)",
    },
    to: {
      width: `${data.length * slideWidth}px`,
      transform: `translateX(${slide * slideWidth * -1}px)`,
    },
    config: {
      tension: 115,
      friction: 26,
    },
    onStart: () => setTimeout(() => setShowName(true), 250),
    onRest: () => setShowName(true),
  });

  const slideUp = useSpring({
    // delay: 300,
    from: {
      opacity: 0,
      transform: "translateY(100px)",
    },
    to: {
      opacity: showName ? 1 : 0,
      transform: showName ? "translateY(0px)" : "translateY(100px)",
    },
    config: {
      tension: 80,
      friction: 27,
    },
  });

  const handleNext = () => {
    setShowName(false);
    if (slide + 1 === data.goals.length) {
      setSlide(0);
    } else {
      setSlide(slide + 1);
    }
  };

  const handlePrev = () => {
    setShowName(false);
    if (slide === 0) {
      setSlide(data.goals.length - 1);
    } else {
      setSlide(slide - 1);
    }
  };

  const handleGoalClick = (index: number) => {
    setSlide(index);
  };

  return (
    <div className="md:w-full text-white py-10 md:py-32 mx-8 md:mx-0 md:px-4 lg:px-0">
      <AnimationFade>
        <div className="w-full md:flex">
          <div className="md:w-1/2">
            <BgImage
              src={data.image[0]}
              style={{ height: isMobile ? "370px" : "619px" }}
            />
          </div>
          <div className="md:w-1/2 flex flex-col justify-center mt-16 md:mt-0">
            <div
              className="max-w-lg  md:w-full mx-8 md:mx-auto typography--container colored--typography--container text-center"
              dangerouslySetInnerHTML={{ __html: data.header }}
            ></div>
            <div className="w-full flex justify-around text-xs font-body font-light">
              <div className="flex justify-center md:mb-0 w-full md:w-auto my-2 md:my-10">
                <div
                  className="px-3 cursor-pointer"
                  onClick={() => handlePrev()}
                >
                  <svg
                    width="19"
                    height="35"
                    viewBox="0 0 19 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.5 34L1.5 17.5L18.5 0.999999"
                      stroke="#FAFAFA"
                    />
                  </svg>
                </div>
                <div className="text-center px-3 flex justify-center text-2xl font-display font-thin mt-px pt-px text-white">
                  <div>{slide + 1}</div>
                  <div>/</div>
                  <div>{data.goals.length}</div>
                </div>
                <div
                  className="px-3 cursor-pointer"
                  onClick={() => handleNext()}
                >
                  <svg
                    width="19"
                    height="35"
                    viewBox="0 0 19 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0.5 1L17.5 17.5L0.5 34" stroke="#FAFAFA" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="pt-4 md:pt-10">
              <div className="overflow-hidden" ref={slideRef}>
                <animated.div className="flex" style={transition} {...handlers}>
                  {data.goals.map((item, index) => (
                    <div
                      style={{ width: `${slideWidth}px` }}
                      key={`slide_${index}`}
                    >
                      <div
                        className="text-center"
                        style={{ width: `${slideWidth}px` }}
                      >
                        <h4 className="text-xl font-display font-thin uppercase">
                          {data.goals[index].goalName}
                        </h4>
                        <div
                          className="max-w-md w-full md:w-5/6 mx-auto py-4  typography--container"
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        />
                      </div>
                    </div>
                  ))}
                </animated.div>
              </div>
            </div>
          </div>
        </div>
      </AnimationFade>
    </div>
  );
};
