import React from "react";

const gaps = {
  smallDesktopOnly: "my-0 md:my-16",
  small: "my-8 md:my-16",
  medium: "my-16 md:my-24",
  large: "my-24 md:my-40",
  extraLarge: "my-40 md:my-56",
};

export const Gap = ({ data }) => <div className="my-16 md:my-32"></div>;
