import { Link, navigate } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import AnimationFade from "../animations/fade.component";
import { ImageComponent } from "../images/image.component";

export const FoundersComponent = ({ data }: any) => (
  <AnimationFade>
    <div className="max-w-7xl mx-8 md:mx-auto py-20 md:py-32 border-white">
      <div
        className="typography--container text-center text-white font-thin"
        dangerouslySetInnerHTML={{ __html: data.heading }}
      />
      <div
        className="typography--container md:larger-body max-w-3xl md:mx-auto text-white text-center tracking-widest md:px-4"
        dangerouslySetInnerHTML={{ __html: data.body }}
      />

      <div className="flex justify-center">
        <button
          onClick={() => navigate("/about-us#team")}
          className="rounded-full text-xs uppercase tracking-[0.25em] py-3 px-12 font-body font-light text-white border border-white border-opacity-100 mt-10 bg-black hover:text-black hover:bg-white transition-colors"
        >
          Meet The Team
        </button>
      </div>
    </div>
  </AnimationFade>
);
