import React from "react";
import useIsMobile from "../../hooks/useIsMobile";
import AnimationFade from "../animations/fade.component";
import { BgImage } from "../images/background-image.component";

export const QuoteWithImage = ({ data }: any) => {
  const isMobile = useIsMobile();

  return (
    <AnimationFade>
      <div className="max-w-7xl mx-8 md:mx-auto border-t border-b border-white flex flex-col-reverse md:flex-row py-24 md:py-0">
        <div
          className="md:w-1/2 flex flex-col justify-center mt-24 md:mt-0"
          style={{ height: isMobile ? "auto" : "500px" }}
        >
          <div
            className="max-w-lg mx-auto text-white font-display text-3xl text-center italic tracking-wider"
            dangerouslySetInnerHTML={{ __html: data.quote }}
          />
        </div>
        <div className="md:w-1/2">
          <BgImage
            src={data.image[0]}
            style={{ width: "100%", height: isMobile ? "370px" : "500px" }}
          />
        </div>
      </div>
    </AnimationFade>
  );
};
