import React, { useState } from "react";
import { animated, config, useSpring } from "react-spring";
import useIsMobile from "../../hooks/useIsMobile";
import { prettyNumber } from "../../utils/prettyNumber";
import AnimationFade from "../animations/fade.component";
import { BgImage } from "../images/background-image.component";
import { ShortLine } from "../line/short-line.component";

const Item = ({ item }: any) => {
  const isMobile = useIsMobile();

  const [hovered, setHovered] = useState(false);
  const overlayAnimation = useSpring({
    opacity: hovered ? 1 : 0,
    config: config.slow,
  });

  const containerAnimation = useSpring({
    transform: hovered ? "translateY(0%)" : "translateY(100%)",
  });

  const textAnimation = useSpring({
    lineHeight: hovered ? "140%" : "500%",
  });

  const headerAnimation = useSpring({
    transform: hovered ? "translateY(10px)" : "translateY(0px)",
    config: config.slow,
  });

  return (
    <div className="text-white">
      <div className="text-center">
        <animated.div style={headerAnimation}>
          <h4 className="font-display text-2xl tracking-widest">
            {item.charityName}
          </h4>
        </animated.div>
        <div
          onMouseEnter={() => !isMobile && setHovered(true)}
          onMouseLeave={() => !isMobile && setHovered(false)}
        >
          <BgImage
            src={item.images[0]}
            style={{ height: "400px", marginTop: "32px" }}
          >
            <animated.div
              style={overlayAnimation}
              className="cursor-pointer w-full h-full bg-black bg-opacity-80 overflow-hidden"
            >
              <animated.div
                style={containerAnimation}
                className="flex flex-col justify-center h-full px-16"
              >
                <animated.p
                  style={textAnimation}
                  className="font-body tracking-widest font-light text-md"
                >
                  {item.shortDescription}
                </animated.p>
                {/* <div className="flex justify-center">
                  <button className="rounded-full text-xs uppercase tracking-[0.25em] py-3 px-12 font-body font-light text-white border border-white border-opacity-1 mt-4 bg-black bg-opacity-0 hover:text-black hover:bg-white transition-colors">
                    Read More
                  </button>
                </div> */}
              </animated.div>
            </animated.div>
          </BgImage>
          <div className="md:hidden mt-8 mb-16">
            <p className="font-body tracking-widest font-light text-sm">
              {item.shortDescription}
            </p>
            {/* <div className="flex justify-center mt-4">
              <button className="rounded-full text-xs uppercase tracking-[0.25em] py-3 px-12 font-body font-light text-white border border-white border-opacity-1 mt-4 bg-black bg-opacity-0 hover:text-black hover:bg-white transition-colors">
                Read More
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CharitiesComponent = ({ data }: any) => {
  const isMobile = useIsMobile();

  return (
    <AnimationFade>
      <div className="w-full my-32">
        <div className="max-w-7xl mx-auto text-white">
          <div
            className="typography--container max-w-lg mb-12 text-center mx-auto px-6 md:px-0 larger-body tracking-wider"
            dangerouslySetInnerHTML={{ __html: data.header }}
          />
          <div className="md:w-full md:grid grid-cols-3 gap-4 pt-10 mx-8 md:mx-0">
            {data.charityMembers.map((item) => (
              <Item item={item} />
            ))}
          </div>
        </div>
        {/* <div className="flex justify-center">
          <button className="rounded-full text-xs uppercase tracking-[0.25em] py-3 px-16 md:mt-16 font-body font-light text-white border border-white border-opacity-1 bg-black bg-opacity-0 hover:text-black hover:bg-white transition-colors">
            View All
          </button>
        </div> */}
      </div>
    </AnimationFade>
  );
};
