import React, { useState } from "react"
import { useSpring, animated } from "react-spring"
import VizSensor from "react-visibility-sensor"
import useMediaQuery from "../../hooks/useMediaQuery"

type Props = {
  children: any
  delay?: number
  offset?: number
}

const AnimationFade = ({ children, delay, offset = 500 }: Props) => {
  const active = useMediaQuery("(min-width: 768px)")
  const [imgViz, setImgViz] = useState(false)
  const style = useSpring({
    delay: delay ? delay : 0,
    from: {
      opacity: "0",
      transform: "translateY(100px)",
    },
    to: {
      opacity: imgViz ? "1" : "0",
      transform: imgViz ? "translateY(0)" : "translateY(100px)",
    },
    config: {
      tension: 97,
      friction: 28,
    },
  })

  return (
    <React.Fragment>
      {active ? (
        <VizSensor
          partialVisibility
          offset={{ top: offset }}
          active={!imgViz}
          onChange={isVisible => {
            setImgViz(isVisible)
          }}
        >
          <animated.div className="relative" style={style}>
            {children}
          </animated.div>
        </VizSensor>
      ) : (
        <>{children}</>
      )}
    </React.Fragment>
  )
}

export default AnimationFade
