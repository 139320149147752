import React from "react";
import useStateIfMounted from "../../hooks/useStateIfMounted.hook";
import { BgImage } from "../images/background-image.component";

export const TeamMobileComponent = ({ data }) => {
  const [slide, setSlide] = useStateIfMounted(null);

  const handleOpenSlide = (index: number) => {
    setSlide(index);
  };

  const handleCloseSlide = () => {
    setSlide(null);
  };

  const Item = ({ item, index }) => {
    return (
      <div
        className="mb-6 cursor-pointer"
        onClick={() => handleOpenSlide(index)}
      >
        <div className="h-px bg-white mb-12" />
        <h2 className="text-3xl font-display font-thin">{item.memberName}</h2>
        <div
          className="text font-body mt-1 font-normal mb-4 pr-16"
          dangerouslySetInnerHTML={{ __html: item.memberArea }}
        />

        <BgImage src={item.images[0]} style={{ height: "149px" }} />
      </div>
    );
  };

  const ActiveSlide = ({ item }) => {
    return (
      <div className="mb-6 relative" onClick={() => handleCloseSlide()}>
        <button className="absolute top-14 right-0">
          <svg
            width="24"
            height="23"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.35355"
              y1="0.646447"
              x2="22.7598"
              y2="22.0527"
              stroke="#FAFAFA"
            />
            <line
              x1="1.12692"
              y1="22.0566"
              x2="22.5332"
              y2="0.650366"
              stroke="#FAFAFA"
            />
          </svg>
        </button>
        <div className="h-px bg-white mb-12" />
        <h2 className="text-3xl font-display font-thin">{item.memberName}</h2>
        <div
          className="text font-body mt-1 font-normal mb-4 pr-16"
          dangerouslySetInnerHTML={{ __html: item.memberArea }}
        />
        <div
          className="text-sm font-body font-light mt-8 mb-8 opacity-75 pr-16"
          dangerouslySetInnerHTML={{ __html: item.memberDescription }}
        />
        <BgImage src={item.images[0]} style={{ height: "318px" }} />
      </div>
    );
  };
  return (
    <div className="md:hidden text-white mx-8">
      <h3 className="text-4xl font-display font-thin text-white text-center mb-16">
        The Considered
        <br className="md:hidden" /> Ask Team
      </h3>
      <div>
        {slide === null && (
          <>
            {data.teamMembers.map((item, index) => (
              <Item item={item} index={index} key={index + "member_mobile"} />
            ))}
          </>
        )}
        {slide !== null && <ActiveSlide item={data.teamMembers[slide]} />}
      </div>
    </div>
  );
};
