import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import useIsMobile from "../../hooks/useIsMobile";
import AnimationFade from "../animations/fade.component";
import { BgImage } from "../images/background-image.component";

export const LeftRightComponent = ({ data }) => {
  console.log(data);
  const [mounted, setMounted] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, [setMounted]);

  const animateWidth = useSpring({
    delay: 1500,
    width: mounted ? "100%" : "0%",
  });

  return (
    <div className="md:pt-0 pb-16 md:pb-32 text-white mx-4 md:mx-0 mt-24 md:mt-0">
      <AnimationFade>
        <div className="max-w-7xl mx-auto relative">
          <animated.div
            className="absolute w-full h-1 bg-silver-gray"
            style={{
              display: data.lineThrough ? "block" : "none",
              transform: "translateY(72px)",
              ...animateWidth,
            }}
          />
          <div
            className={`md:flex px-4 md:px-0 ${
              data.direction ? "flex-row-reverse" : ""
            }`}
          >
            <div className="md:w-1/2 ">
              <AnimationFade>
                <BgImage
                  src={data.images[0]}
                  style={{ minHeight: isMobile ? "300px" : "564px" }}
                />
              </AnimationFade>
            </div>
            <div
              className={`md:w-1/2 md:px-8 mt-8 md:mt-0 ${
                data.wide ? "md:pl-0 md:pr-24" : "md:px-24"
              } flex flex-col justify-center ${
                data.lineThrough ? "mt-10" : ""
              }`}
            >
              <AnimationFade>
                <div
                  className="typography--container larger-body"
                  dangerouslySetInnerHTML={{ __html: data.body }}
                />
                {data.buttonLabel && (
                  <div className="flex justify-center md:justify-start">
                    <Link to={data.buttonLink}>
                      <button className="rounded-full text-xs uppercase tracking-[0.25em] py-3 px-12 font-body font-light text-white border border-white border-opacity-30 mt-10 bg-black hover:text-black hover:bg-white transition-colors">
                        {data.buttonLabel}
                      </button>
                    </Link>
                  </div>
                )}
              </AnimationFade>
            </div>
          </div>
        </div>
      </AnimationFade>
    </div>
  );
};
