import React, { useEffect, useLayoutEffect, useMemo, useState } from "react"

const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false)
  const [initial, setInitial] = useState(false)

  useEffect(() => {
    if (!initial) {
      setIsMobile(window.innerWidth < 768)
      setInitial(true)
    }
  }, [])

  useLayoutEffect(() => {
    const updateSize = (): void => {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener("resize", updateSize)
    // updateSize();

    return (): void => window.removeEventListener("resize", updateSize)
  }, [])

  return useMemo(() => isMobile, [isMobile])
}

export default useIsMobile
