import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { Loop } from "../components/component-loop/loop.component";
import { Footer } from "../components/footer/footer.component";

const SinglePage = ({ pageContext, location }) => {
  console.log("CTX", pageContext);
  return (
    <Layout>
      <Seo title={`${pageContext.data.title} | The Considered Ask`} />
      {pageContext.data && (
        <Loop
          data={{
            components: pageContext.data.pageComponents,
            menu: pageContext.menuItems,
            location: location,
          }}
        />
      )}
      <Footer data={{ menu: pageContext.menuItems }} />
    </Layout>
  );
};

export default SinglePage;
