import { useEffect, useState } from "react";

function debounce(method, delay) {
  clearTimeout(method._tId);
  method._tId = setTimeout(function () {
    method();
  }, delay);
}

export const useWindowScroll = () => {
  const [scrollY, setScrollY] = useState(0);
  const [scrolled, setScrolled] = useState(0);

  const handleScroll = () => {
    debounce(() => {
      if (scrollY == window.scrollY) {
      } else {
        setScrollY(window.scrollY);
        setScrolled((window.scrollY / document.body.offsetHeight) * 100);
      }
    }, 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return { scrollY: scrollY, scrolled: scrolled };
};
