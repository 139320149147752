import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { CSSProperties } from "react";
import useIsMobile from "../../hooks/useIsMobile";

interface Props {
  src: any;
  style?: CSSProperties;
  imgStyle?: CSSProperties;
  objectFit?: any;
  objectPosition?: any;
  className?: string;
  Tag?: any;
}

export const ImageComponent: React.FC<Props> = (props) => {
  const isMobile = useIsMobile();
  const { src, style, imgStyle, objectFit, className, objectPosition } = props;

  const images = useStaticQuery(graphql`
    query ImageComponentQuery {
      allImageSharp {
        edges {
          node {
            gatsbyImageData
            fixed {
              originalName
            }
          }
        }
      }
    }
  `);

  const image = images.allImageSharp.edges.find(
    (n) => n.node.fixed.originalName === src.filename
  );

  return (
    <React.Fragment>
      {src && image ? (
        <GatsbyImage
          image={image.node.gatsbyImageData}
          alt={"kek"}
          imgStyle={imgStyle}
          style={style}
          objectFit={objectFit}
          objectPosition={objectPosition}
          className={className}
        />
      ) : (
        <div className="bg-black bg-opacity-75">{props.children}</div>
      )}
    </React.Fragment>
  );
};
