import React, { ReactComponentElement } from "react";
import { DoubleImageBanner } from "../banner/banner.component";
import { CharitiesComponent } from "../charities/charities.component";
import { ContactComponent } from "../contact/contact.component";
import { Focus } from "../focus/focus.component";
import { FoundersComponent } from "../founders/founders.component";
import { FullWidthImage } from "../full-width-image/full-width-image.component";
import { Gap } from "../gap/gap.component";
import { GoalsSliderComponent } from "../goals/goals.component";
import { Hero } from "../hero/hero.component";
import { LeftRightCaptionComponent } from "../left-right/left-right-caption.component";
import { LeftRightComponent } from "../left-right/left-right.component";
import { LineSeparator } from "../line/line.component";
import { Nav } from "../nav/nav.component";
import { PlainTextBlock } from "../plain-text/plain-text.component";
import { QuoteWithImage } from "../quote/quote.component";
import { TeamSliderComponent } from "../team/team.component";
import { TypographyBlock } from "../typography-block/typography-block.component";

export type MenuItems = {
  name: string;
  slug: string;
};

type Props = {
  data: {
    components: any;
    menu: MenuItems[];
    location: any;
  };
};

export const Loop = ({ data }: Props) => {
  console.log(data);

  const filter = data.components.filter((el) => el);
  const allComponents = {
    hero: (props) => <Hero data={props} />,
    pageHeader: () => <Nav data={data.menu} location={data.location} />,
    ourFocus: (props) => <Focus data={props} />,
    leftRightComponent: (props) => <LeftRightComponent data={props} />,
    leftRightWithImageCaption: (props) => (
      <LeftRightCaptionComponent data={props} />
    ),
    typographyBlock: (props) => <TypographyBlock data={props} />,
    founders: (props) => <FoundersComponent data={props} />,
    charities: (props) => <CharitiesComponent data={props} />,
    lineSeparator: (props) => <LineSeparator data={props} />,
    doubleImageBanner: (props) => <DoubleImageBanner data={props} />,
    goalsSlider: (props) => <GoalsSliderComponent data={props} />,
    teamSection: (props) => <TeamSliderComponent data={props} />,
    contact: (props) => <ContactComponent data={props} />,
    gap: (props) => <Gap data={props} />,
    plainTextBlock: (props) => <PlainTextBlock data={props} />,
    quoteWithImage: (props) => <QuoteWithImage data={props} />,
    fullWidthImage: (props) => <FullWidthImage data={props} />,
  };

  const renderComponent = (
    props: any
  ): ReactComponentElement<any> | HTMLElement => {
    return allComponents[props.typeHandle] ? (
      allComponents[props.typeHandle](props)
    ) : (
      <></>
    );
  };

  return (
    <div>
      {filter.map((component: any, index: number) => (
        <React.Fragment key={component.typeHandle + "_" + index}>
          {renderComponent(component)}
        </React.Fragment>
      ))}
    </div>
  );
};
