import React, { useEffect, useRef, useState } from "react";
import { animated, useSprings, config, useSpring } from "react-spring";
import AnimationFade from "../animations/fade.component";
import useIsMobile from "../../hooks/useIsMobile";
import { LineSeparator } from "../line/line.component";
import { BgImage } from "../images/background-image.component";

type props = {
  data: any;
};

export const Hero = ({ data }: props) => {
  const isMobile = useIsMobile();

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, [setMounted]);

  const heroImageAnimation = useSpring({
    width: mounted ? "100%" : "0%",
    height: isMobile ? "450px" : "675px",
    opacity: mounted ? "1" : "0.5",
  });
  const heroImageCoverAnimation = useSpring({
    delay: 100,
    width: "100%",
    height: isMobile ? "450px" : "675px",
    opacity: mounted ? "0.22" : "0.7",
    transform: "translateY(-100%)",
  });

  return (
    <div
      className="relative overflow-hidden mx-8 md:mx-0"
      style={{
        maxHeight: isMobile ? "600px" : "924px",
        marginBottom: isMobile ? "0" : "0px",
      }}
    >
      <div className="max-w-7xl mx-auto mt-20 md:mt-20 md:mb-0 relative md:pb-8 px-7 md:px-0 text-white  z-10">
        <AnimationFade>
          <div
            className="max-w-[1035px] mx-auto hero--typography--container text-center"
            dangerouslySetInnerHTML={{ __html: data.body }}
          />
        </AnimationFade>
      </div>
      <div className="md:hidden h-1 w-full px-4 mt-16 mb-16 ">
        <div className="h-full" style={{ backgroundColor: "#7E7E7E" }} />
      </div>
      <div
        className="w-full relative"
        style={{ transform: "translateY(-119px" }}
      >
        <animated.div className="overflow-hidden" style={heroImageAnimation}>
          <BgImage
            src={data.backgroundImage[0]}
            style={{ height: isMobile ? "450px" : "675px" }}
          />
        </animated.div>
        <animated.div
          className="bg-black absolute mix-blend-multiply"
          style={heroImageCoverAnimation}
        ></animated.div>
      </div>
    </div>
  );
};
