import React from "react";
import AnimationFade from "../animations/fade.component";

export const ContactComponent = ({ data }: any) => (
  <div className="max-w-7xl mx-auto w-full text-white border-b border-white">
    <AnimationFade offset={0}>
      <div className="md:flex justify-around  py-8 md:py-0 mt-6 md:mt-0">
        {data.social.map((item, index) => (
          <div
            key={item.networkLink}
            className="mx-2 font-display text-2xl md:text-xl uppercase  py-6 md:py-0 md:mt-10 md:mb-14 text-center md:text-left"
          >
            <a
              target="_blank"
              rel="noopener noreferer"
              href={`${item.networkLink}`}
            >
              {item.socialNetwork}
            </a>
          </div>
        ))}
      </div>
    </AnimationFade>
  </div>
);
