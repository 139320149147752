import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import useIsMobile from "../../hooks/useIsMobile";
import AnimationFade from "../animations/fade.component";
import { BgImage } from "../images/background-image.component";

export const FullWidthImage = ({ data }: any) => {
  const isMobile = useIsMobile();

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, [setMounted]);

  const heroImageAnimation = useSpring({
    delay: 1000,
    width: mounted ? "100%" : "0%",
    opacity: mounted ? "1" : "0.25",
  });

  return (
    <AnimationFade>
      <animated.div
        className="overflow-hidden px-8 md:px-0"
        style={heroImageAnimation}
      >
        <BgImage
          src={data.image[0]}
          style={{ height: isMobile ? "300px" : "500px" }}
        />
      </animated.div>
    </AnimationFade>
  );
};
