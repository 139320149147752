import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";

const useWindowHeight = (): number => {
  const [height, setHeight] = useState(0);
  const [initial, setInitial] = useState(false);

  useEffect(() => {
    if (!initial && window) {
      setHeight(window.innerHeight);
      setInitial(true);
    }
  }, []);

  useLayoutEffect(() => {
    const updateSize = (): void => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateSize);
    // updateSize();

    return (): void => window.removeEventListener("resize", updateSize);
  }, []);

  return useMemo(() => height, [height]);
};

export default useWindowHeight;
