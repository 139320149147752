import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import useIsMobile from "../../hooks/useIsMobile";
import useStateIfMounted from "../../hooks/useStateIfMounted.hook";
import AnimationFade from "../animations/fade.component";
import { BgImage } from "../images/background-image.component";
import { ImageComponent } from "../images/image.component";

type props = {
  data: {
    label: string;
    row: any[];
    typography: any;
    image: any;
    mobileimage: any;
  };
};

const FocusItem = ({ item, last, index }: any) => {
  const [hovered, setHovered] = useStateIfMounted(false);
  const headerAnimation = useSpring({
    transform: hovered ? "translateY(0px)" : "translateY(24px)",
  });
  const bodyAnimation = useSpring({
    height: "40px",
    paddingTop: hovered ? "0px" : "40px",
    lineHeight: hovered ? "20px" : "100px",
    opacity: hovered ? "1" : "0",
  });

  const topBorderHover = useSpring({
    opacity: hovered ? "1" : "0.3",
  });
  const bottomBorderHover = useSpring({
    opacity: hovered ? "1" : "0",
  });

  const hoverImageAnimation = useSpring({
    opacity: hovered ? "1" : "0",
    transform: hovered ? "translateY(0%)" : "translateY(120%)",
  });

  console.log(index);

  return (
    <>
      <AnimationFade>
        <animated.div style={topBorderHover} className="h-px bg-white" />
        <div
          className="cursor-pointer w-full text-white py-4 relative"
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
        >
          <animated.h2
            style={headerAnimation}
            className="font-display uppercase text-4xl tracking-[0.25em] text-center"
          >
            {item.header}
          </animated.h2>
          <animated.p
            style={bodyAnimation}
            className="font-body font-light max-w-xl text-center mx-auto text-sm text-white text-opacity-75 tracking-wider mt-2 overflow-hidden"
            dangerouslySetInnerHTML={{ __html: item.body }}
          />
          <div
            className={`absolute ${
              (index + 1) % 2 == 0 ? `left-16` : `right-16`
            } overflow-hidden bottom-0`}
            style={{
              width: "256px",
              height: "218px",
              // transform: "translateY(-45%)",
            }}
          >
            <animated.div style={hoverImageAnimation}>
              <ImageComponent
                src={item.hoverImage[0]}
                style={{
                  width: "256px",
                  height: "218px",
                }}
                objectFit="contain"
                objectPosition="center bottom"
              />
            </animated.div>
          </div>
        </div>
        <animated.div style={bottomBorderHover} className="h-px bg-white" />
        {last && !hovered && <div className="h-px bg-white bg-opacity-30" />}
      </AnimationFade>
    </>
  );
};

const MobileFocusItem = ({ item }) => {
  return (
    <AnimationFade>
      <div className="cursor-pointer w-full text-white py-4 relative mb-8">
        <animated.h2 className="font-display uppercase text-4xl tracking-[0.25em] text-center">
          {item.header}
        </animated.h2>
        <animated.p
          className="font-body font-light max-w-xl text-center mx-auto text-sm text-white text-opacity-75 tracking-wider mt-2 overflow-hidden"
          dangerouslySetInnerHTML={{ __html: item.body }}
        />
      </div>
      <div className="flex justify-center">
        <ImageComponent
          src={item.hoverImage[0]}
          style={{
            width: "256px",
            height: "218px",
          }}
          objectFit="contain"
          objectPosition="center bottom"
        />
      </div>
      <animated.div className="h-px bg-white mb-16" />
    </AnimationFade>
  );
};

export const Focus = ({ data }: props) => {
  const isMobile = useIsMobile();
  const [mounted, setMounted] = useState(false);
  const [phaseTwo, setPhaseTwo] = useState(false);
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, [setMounted]);

  useEffect(() => {
    setTimeout(() => {
      setPhaseTwo(true);
    }, 1000);
  }, []);

  console.log(phaseTwo);

  const lineAnimation = useSpring({
    delay: 1000,
    width: mounted ? "100%" : "0%",
    transform: mounted && phaseTwo ? "translateY(225px)" : "translateY(0px)",
  });

  const blackBoxAnimation = useSpring({
    delay: 1000,
    height: phaseTwo ? "0px" : "300px",
    transform: phaseTwo ? "translateY(225px)" : "translateY(0px)",
  });

  const photoAnimation = useSpring({
    delay: 1200,
    opacity: phaseTwo ? "1" : "0",
    height: "225px",
  });

  return (
    <>
      {!isMobile && (
        <>
          <AnimationFade>
            <div className="max-w-7xl mx-8 md:mx-auto mt-4 overflow-hidden relative">
              <h3 className="font-display font-thin text-4xl text-white text-center mb-12 tracking-wider">
                {data.label}
              </h3>

              <div className="">
                {data.row.map((item, index) => (
                  <FocusItem
                    item={item}
                    last={data.row.length == index + 1}
                    index={index}
                  />
                ))}
              </div>
              <AnimationFade>
                <div
                  className="mt-24 typography--container text-white max-w-3xl md:mx-auto text-center"
                  dangerouslySetInnerHTML={{ __html: data.typography }}
                ></div>
                <div className="flex justify-center mb-16">
                  {/* <button className="rounded-full text-xs uppercase tracking-[0.25em] py-3 px-12 font-body font-light text-white border border-white border-opacity-20 mt-10 bg-black hover:text-black hover:bg-white transition-colors">
                    Read More
                  </button> */}
                </div>
              </AnimationFade>
            </div>
          </AnimationFade>
          <div className="max-w-7xl mx-auto  md:mb-32 relative overflow-hidden">
            <animated.div
              style={lineAnimation}
              className="absolute border-t border-white border-opacity-1 h-px z-20"
            />
            <animated.div
              style={blackBoxAnimation}
              className="bg-black absolute w-full z-10"
            />
            <animated.div
              className="w-full mb-px flex justify-around"
              style={photoAnimation}
            >
              <BgImage
                src={data.image[0]}
                style={{
                  height: "100%",
                  width: "100%",
                  maxWidth: "959px",
                }}
              />
            </animated.div>
          </div>
        </>
      )}

      {isMobile && (
        <>
          <AnimationFade>
            <div className="max-w-7xl mx-8 md:mx-auto mt-16 overflow-hidden relative">
              <h3 className="font-display font-thin text-4xl text-white text-center mb-12">
                {data.label}
              </h3>
              <div className="h-px bg-white mb-10" />
              <div className="">
                {data.row.map((item, index) => (
                  <MobileFocusItem item={item} key={`mobile_focus_${index}`} />
                ))}
              </div>
              <AnimationFade>
                <div
                  className="mt-24 typography--container text-white text-center"
                  dangerouslySetInnerHTML={{ __html: data.typography }}
                ></div>
                <div className="flex justify-center mb-4 md:mb-16">
                  {/* <button className="rounded-full text-xs uppercase tracking-[0.25em] py-3 px-12 font-body font-light text-white border border-white border-opacity-20 mt-10 bg-black hover:text-black hover:bg-white transition-colors">
                    Read More
                  </button> */}
                </div>
              </AnimationFade>
            </div>
          </AnimationFade>
          <div className="max-w-7xl mx-auto  md:mb-32 relative overflow-hidden">
            <animated.div
              style={lineAnimation}
              className="absolute border-t border-white border-opacity-1 h-px z-20"
            />
            <animated.div
              style={blackBoxAnimation}
              className="bg-black absolute w-full z-10"
            />

            <BgImage
              src={data.mobileimage[0]}
              style={{
                height: "164px",
                width: "100%",
                maxWidth: "959px",
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
