import React from "react";
import AnimationFade from "../animations/fade.component";

export const TypographyBlock = ({ data }: any) => {
  return (
    <AnimationFade>
      <div
        className={`max-w-7xl mx-8 md:mx-auto py-20 md:py-20 md:px-8 xl:px-0 typography--container typography--block text-white ${
          data.topLine ? "border-t border-white border-opacity-1" : ""
        } `}
      >
        <div className="max-w-6xl mx-auto">
          <div
            className={data.textAlignment}
            dangerouslySetInnerHTML={{ __html: data.body }}
          />
        </div>
        <div className="flex justify-around">{}</div>
      </div>
    </AnimationFade>
  );
};
