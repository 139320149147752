import React from "react";
import { LineSeparator } from "../line/line.component";

export const PlainTextBlock = ({ data }: any) => (
  <>
    <div className="max-w-3xl mx-8 md:mx-auto md:w-full typography-block--container policy text-white text-center">
      <div
        className="my-16 pt-16 md:pt-0 md:my-24 px-4 md:px-8"
        dangerouslySetInnerHTML={{ __html: data.body }}
      />
    </div>
  </>
);
