import React from "react";
import { animated, useSpring } from "react-spring";
import useIsMobile from "../../hooks/useIsMobile";
import useStateIfMounted from "../../hooks/useStateIfMounted.hook";
import AnimationFade from "../animations/fade.component";
import { BgImage } from "../images/background-image.component";
import { TeamMobileComponent } from "./team-mobile.component";

export const TeamSliderComponent = ({ data }) => {
  const isMobile = useIsMobile();
  const [slide, setSlide] = useStateIfMounted(0);
  const [open, setOpen] = useStateIfMounted(false);

  const handleNext = () => {
    if (slide + 1 === data.teamMembers.length) {
      setSlide(0);
    } else {
      setSlide(slide + 1);
    }
  };

  const handlePrev = () => {
    if (slide === 0) {
      setSlide(data.teamMembers.length - 1);
    } else {
      setSlide(slide - 1);
    }
  };

  const ListItem = ({ item, index }) => {
    const [hovered, setHovered] = useStateIfMounted(index == slide);

    const showArrow = useSpring({
      opacity: hovered ? 1 : 0,
    });

    return (
      <>
        <div className="w-full h-px bg-white" id="team" />
        <div
          className="flex flex-col justify-center text-white pl-10 cursor-pointer"
          style={{ height: "187px" }}
          onMouseOver={() => {
            setHovered(true);
            setSlide(index);
          }}
          onMouseOut={() => {
            setHovered(false);
          }}
          onClick={() => {
            setOpen(true);
            setSlide(index);
          }}
        >
          <animated.div className="relative">
            <h4 className="text-2xl font-display font-thin">
              {item.memberName}
            </h4>
            <div
              className="text font-body font-light mt-1"
              dangerouslySetInnerHTML={{ __html: item.memberArea }}
            />
            <animated.div
              className="absolute right-10 h-full top-0 flex flex-col justify-center"
              style={showArrow}
            >
              <svg
                width="19"
                height="35"
                viewBox="0 0 19 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1L18 17.5L1 34" stroke="#FAFAFA" />
              </svg>
            </animated.div>
          </animated.div>
        </div>
        {index + 1 == data.teamMembers.length && (
          <div className="w-full h-px bg-white" />
        )}
      </>
    );
  };

  const SlideItem = ({ item }) => {
    return (
      <div
        className="border-t border-white text-white relative flex flex-col justify-between"
        style={{ height: "560px" }}
      >
        <div
          className="absolute top-6 right-2 p-4 cursor-pointer z-10"
          onClick={() => setOpen(false)}
        >
          <svg
            width="24"
            height="23"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.35355"
              y1="0.646447"
              x2="22.7598"
              y2="22.0527"
              stroke="#FAFAFA"
            />
            <line
              x1="1.12692"
              y1="22.0566"
              x2="22.5332"
              y2="0.650366"
              stroke="#FAFAFA"
            />
          </svg>
        </div>
        <AnimationFade>
          <div className="pl-10 pr-16 mt-16">
            <div>
              <h2 className="text-4xl font-display font-thin mb-8">
                {item.memberName}
              </h2>
            </div>
            <div
              className="text font-body mt-1 font-normal"
              dangerouslySetInnerHTML={{ __html: item.memberArea }}
            />
            <div
              className="text-sm font-body font-light mt-8 opacity-75 pr-16"
              dangerouslySetInnerHTML={{ __html: item.memberDescription }}
            />
          </div>
        </AnimationFade>
        <div className="flex">
          <div className="w-3/5 flex flex-col justify-center">
            <div className="w-full h-px bg-white" />
          </div>
          <div className="flex justify-center w-2/5">
            <div className="px-3 cursor-pointer" onClick={() => handlePrev()}>
              <svg
                width="19"
                height="35"
                viewBox="0 0 19 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M18.5 34L1.5 17.5L18.5 0.999999" stroke="#FAFAFA" />
              </svg>
            </div>
            <div className="text-center px-3 flex justify-center text-2xl font-display font-thin mt-px pt-px text-white">
              <div>{slide + 1}</div>
              <div>/</div>
              <div>{data.teamMembers.length}</div>
            </div>
            <div className="px-3 cursor-pointer" onClick={() => handleNext()}>
              <svg
                width="19"
                height="35"
                viewBox="0 0 19 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.5 1L17.5 17.5L0.5 34" stroke="#FAFAFA" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isMobile && <TeamMobileComponent data={data} />}
      {!isMobile && (
        <AnimationFade>
          <div className="max-w-7xl mx-auto mb-24">
            <h3 className="text-4xl font-display font-thin text-white text-center mb-16">
              The Considered
              <br className="md:hidden" /> Ask Team
            </h3>
            <div className="flex">
              <div className="w-2/5 pt-8 mt-1">
                {!open && (
                  <>
                    {data.teamMembers.map((member, index) => (
                      <ListItem
                        item={member}
                        index={index}
                        key={index + "member"}
                      />
                    ))}
                  </>
                )}
                {open && <SlideItem item={data.teamMembers[slide]} />}
              </div>
              <div className="w-3/5">
                <BgImage
                  src={data.teamMembers[slide].images[0]}
                  style={{ height: "636px" }}
                />
              </div>
            </div>
          </div>
        </AnimationFade>
      )}
    </>
  );
};
