import * as React from "react";
import PropTypes from "prop-types";

import "./layout.css";

const Layout = ({ children, global }) => {
  return (
    <>
      <main className="main-wrapper">{children}</main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
