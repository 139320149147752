import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React, { CSSProperties } from "react";
import useIsMobile from "../../hooks/useIsMobile";

interface Props {
  src: any;
  style: CSSProperties;
  Tag?: any;
}

export const BgImage: React.FC<Props> = (props) => {
  const isMobile = useIsMobile();
  const { src, style } = props;

  const images = useStaticQuery(graphql`
    query ImageQuery {
      allImageSharp {
        edges {
          node {
            fluid(quality: 100) {
              base64
              originalName
              src
              srcSet
              sizes
              aspectRatio
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
  `);

  const image = images.allImageSharp.edges.find(
    (n) => n.node.fluid.originalName === src.filename
  );

  const posX = src.focalPoint ? src.focalPoint[0] * 100 + "%" : "50%";
  const posY = src.focalPoint ? src.focalPoint[1] * 100 + "%" : "50%";

  return (
    <React.Fragment>
      {src ? (
        <BackgroundImage
          Tag={props.Tag}
          fluid={image ? image.node.fluid : {}}
          style={{
            ...style,
            backgroundPositionX: posX,
            backgroundPositionY: posY,
          }}
        >
          {props.children}
        </BackgroundImage>
      ) : (
        <div className="bg-black bg-opacity-75">{props.children}</div>
      )}
    </React.Fragment>
  );
};
